.format{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
}

.format3{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
}

.main{
    display: flex;
    flex-direction: row;
}

.intro{
    text-align: left;
    max-width: 55%;
   
}

.subheading1{
    font-size: 2.3rem;
    color: white;

}

.vyshnaviNalla{
    margin-top: -6%;
    margin-left: -1.3%;
    margin-bottom: -3%;
    max-width: 710px;
}

.subheading2{
    color:#CACACA;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 110%;
}

.testing{
    display:flex;
    flex-direction: row;
    column-gap: 5%;
    background-color: #CACACA;
    justify-content: center;
    align-items: center;

}

.i {
    background-color: green;
    
}

.v{
    background-color: yellow;
    visibility: hidden;
    
}

.forThree{
    width: 100%;
    margin-left: 10%;
 
}

.format2{
    padding-left: 10%;
    padding-right: 10%;
}

.format4{
    padding-left: 15%;
    padding-right: 15%;
}

.heading-with-space{
    color:#FFFFFF;
    font-weight: 400;
    font-size: 1.3rem;
    letter-spacing: 10px;
    margin: 10%;
}

.projects-card{
    display: flex;
    flex-direction: row;
    align-items:center;
    height: fit-content;
    border-color: #FFFFFF;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    margin-top: 4%;
   
}

.projects-image{
    border-radius: 50%;
    max-width: 150px;
    min-width: 20px;
    padding: 2%;
    flex-shrink: 0;
}

.projects-text-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    text-align: left;
}

.award-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
}

.award-icon{
    color: white;
    font-size: 1.5rem;
}

.body1, .body2{ 

    color: #FFFFFF;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 140%;
    
}

.subheading3{
    color: #CACACA;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    letter-spacing: 0.7px;
}

.subheading-contact{
    color: #CACACA;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    letter-spacing: 0.7px;
    text-decoration: none;
}

.projects-title-flex{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
    row-gap: 5px;
}

.projects-tag{
    background-color: darkgray;
    border-radius: 20px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
}

.skills-image{
    border-radius: 50%;
    max-width: 120px;
    min-width: 20px;
   
}

.skills{
    padding-top: 20px;
    padding-bottom: 40px;
}

.projects{
    padding-bottom: 40px;
    padding-top: 20px;
}

.skills-projects{
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap;
}

.ind-skill{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-aboutme{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 75px;
    margin-top: 30px;
}

.aboutMe{
    padding-top: 20px;
    padding-bottom: 40px;
}

.image-aboutme{
    border-radius: 50%;
    max-width: 225px;
    min-width: 50px;
    flex-shrink: 0;

}

.flex-contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.icon-text-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    padding-top: 15px;

}

.icon-contact{
    color: white;
    text-decoration: none;
    font-size: 2rem;
}

.contact{
    padding-top: 20px;
    padding-bottom: 40px;
}

.flex-leadership{
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    margin-top: 30px;
   align-items: center;
   text-align: left;
    
}

.leadership{
    padding-bottom: 40px;
    padding-top: 20px;
}

.leadership-image{
    border-radius: 50%;
    max-width: 150px;
    min-width: 20px;
    max-height: 150px;
    
    flex-shrink: 0;
   
}

.text-leadership{
    flex: 1 1 0;

}

.feedback-form-flex{
    display: flex;
    flex-direction: row;
    align-items: start;
    column-gap: 50px;
    margin-top: 15px;
}

.feedback-form-name{

    width: 400px;
    margin-bottom: 30px;
    padding: 10px;
   
}

.feedback-form-thoughts{
    height: 200px;
    width: 400px;
    margin-bottom: 30px;
    padding: 10px;
    
}

input::placeholder{

    color: grey;
    font-weight: 400;
    font-size: 0.98rem;
    line-height: 140%;
    letter-spacing: 0.095em;
    font-family: 'Poppins', sans-serif;

}

input{
    color: black;
    font-weight: 400;
    font-size: 0.98rem;
    line-height: 140%;
    letter-spacing: 0.095em;
    font-family: 'Poppins', sans-serif;

}

textarea{
    color: black;
    font-weight: 400;
    font-size: 0.98rem;
    line-height: 140%;
    letter-spacing: 0.095em;
    font-family: 'Poppins', sans-serif;
}
textarea::placeholder{
    color: grey;
    font-weight: 400;
    font-size: 0.98rem;
    line-height: 140%;
    letter-spacing: 0.095em;
    font-family: 'Poppins', sans-serif;

}

.feedback-form{
    margin-top: 15px;
}

.feedback-button{
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}

.feedback{
    padding-top: 20px;
    padding-bottom: 10%;
}

.projectPhoto{
    max-width: 700px;
    border: 2px solid #FFFFFF;
}

.projectsPageTags{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
}

.navigateHomeButton{
    display: flex;
    justify-content: flex-start;
    margin-right: 5px;
}

.github-link{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    margin: 20px;
}

.projects-links-display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.example-image{
    max-width: 750px;
    padding-top: 5%;
}

.poster-image{
    max-width: 500px;
    padding-top: 5%;
}

.projects-link:hover{
    color: #DF329F;
}


@media screen and (max-width: 1200px){

    
}


@media screen and (max-width: 960px){

    .vyshnaviNalla{
        transform: translate();
        max-width: 400px;
    }


    .subheading1{
        font-size: 2rem;
        color: white;
    }

    .subheading2{
        color:#CACACA;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 110%;
    }

    .subheading3{
        color: #CACACA;
        font-weight: 400;
        font-size: 1 rem;
        line-height: 140%;
        letter-spacing: 0.7px;
    }

    .body1{ 
        color: #FFFFFF;
        font-weight: 400;
        font-size: 0.93rem;
        line-height: 140%;
        letter-spacing: 0.05em;
    }

}

@media screen and (max-width: 760px){ 
    .vyshnaviNalla{
        transform: translate();
        max-width: 350px;
    }

    .subheading1{
        font-size: 1.8rem;
        color: white;
    }

    .subheading2{
        color:#CACACA;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 110%;
    }

    .subheading3{
        color: #CACACA;
        font-weight: 400;
        font-size: 1 rem;
        line-height: 140%;
        letter-spacing: 0.7px;
    }

    .body1{ 
        color: #FFFFFF;
        font-weight: 400;
        font-size: 0.93rem;
        line-height: 140%;
        letter-spacing: 0.05em;
    }

}

@media screen and (max-width: 550px){
    .vyshnaviNalla{
        transform: translate();
        max-width: 250px;
    }

    .main{
        display: flex;
        flex-direction: column-reverse;
    }

    .subheading1{
        font-size: 1.8rem;
        color: white;
    }

    .subheading2{
        color:#CACACA;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 110%;
    }

    .subheading3{
        color: #CACACA;
        font-weight: 400;
        font-size: 1 rem;
        line-height: 140%;
        letter-spacing: 0.7px;
    }

    .body1{ 
        color: #FFFFFF;
        font-weight: 400;
        font-size: 0.93rem;
        line-height: 140%;
        letter-spacing: 0.05em;
    }

    .body2{ 

        color: #FFFFFF;
        font-weight: 400;
        font-size: 1rem;
        line-height: 140%;
        
    }

    .intro{
        text-align: center;
        max-width: 70%;
       
    }
    .main{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        row-gap: 30px;

    }

    .skills-image{
        border-radius: 50%;
        max-width: 75px;
        min-width: 20px;
    }

    .skills{
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .skills-projects{
        display: flex;
        flex-direction: row;
        column-gap: 15px;
        row-gap: 5px;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        flex-wrap: wrap;
    }

    .ind-skill{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 80px;
    }

    .flex-leadership{
        display: flex;
        flex-direction: column;
        column-gap: 50px;
        margin-top: 30px;
       align-items: center;
       text-align: center;
        
    }

    .flex-aboutme{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 75px;
        margin-top: 30px;
    }

    .feedback-form-flex{
        display: flex;
        flex-direction: column;
        align-items: start;
        column-gap: 50px;
        margin-top: 15px;
    }

    .feedback-form-name{

        width: 250px;
        margin-bottom: 30px;
        padding: 10px;
       
    }
    
    .feedback-form-thoughts{
        height: 200px;
        width: 250px;
        margin-bottom: 30px;
        padding: 10px;
        
    }

    .feedback-button{
        display: flex;
        justify-content: center;
        margin-right: 5px;
    }

    .projects-card{
        display: flex;
        flex-direction: column;
        align-items:center;
        height: fit-content;
        border-color: #FFFFFF;
        border-width: 2px;
        border-style: solid;
        border-radius: 10px;
        margin-top: 4%;
       
    }

    .projects-title-flex{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .projects-title-flex>div:first-child {
        flex:100%;
      }

    .award-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        margin-top: 10px;
    }

    .heading-with-space{
        color:#FFFFFF;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 10px;
        margin: 50px;
        margin-top: 60px;
    }

    .projects-image{
        border-radius: 50%;
        max-width: 100px;
        min-width: 20px;
        padding: 10px;
    }

    .leadership-image{
        border-radius: 50%;
        max-width: 100px;
        min-width: 20px;
        max-height: 150px;
        flex-shrink: 0;
       
    }

    .image-aboutme{
        border-radius: 50%;
        max-width: 150px;
        min-width: 50px;
    
    }

    .navigateHomeButton{
        display: flex;
        justify-content: center;
    }

    .example-image{
        max-width: 100%;
        padding-top: 5%;
    }

    .poster-image{
        max-width: 100%;
        padding-top: 5%;
    }

    .projects-text-flex{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
        text-align: center;
    }

    .format{
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 10%;
    }

    .subheading-contact{
        font-size: 0.9rem;
        text-decoration: underline;
    }

    .projects-tag{
        background-color: darkgray;
        border-radius: 20px;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 0.9rem;
    }

    .projects{
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .skills{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .leadership{
        padding-bottom: 20px;
        padding-top: 20px;
    }

    .aboutMe{
        padding-top: 20px;
        padding-bottom: 20px;
    }

    

}


