.outline-button{
  border: 2.5px solid;
  background-color: #151515;
  color: white;
 
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 6px;
  padding-bottom: 8px;
  cursor: pointer;
  border-color: white;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 3px;

}

.outline-button:hover {
  background-color: white;
  color: black;
}